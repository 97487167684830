<template>
  <div class="editorComponentWrapper">
    <div class="editorComponent" v-show="backendOperation == null" v-if="!noAccessRights">
      <div class="top-menu">
        <div class="top-menu-action" @click="goBack()" data-cy='editor-back'><i class="fas fa-arrow-left"></i> <span class='actionTitle'>{{ $t('general.back') }}</span></div>
        <div v-if="!isTemplate" class="top-menu-action" @click="storeToBackend" data-cy='editor-save'><i class="fas fa-save"></i> <span class='actionTitle'>{{ $t('editor.save') }}</span></div>
        <div v-if="!isTemplate && tenant.isAdminTenant" class="top-menu-action" @click="storeAsTemplate"><i class="fas fa-cloud-upload-alt"></i></div>
        <div v-if="!isTemplate" class="top-menu-action" @click="undo"><i class="fas fa-undo"></i> <span class='actionTitle'>{{ $t('editor.undo') }}</span></div>
        <div v-if="!isTemplate" class="top-menu-action" @click="redo"><i class="fas fa-redo"></i> <span class='actionTitle'>{{ $t('editor.redo') }}</span></div>
        <div v-if="!isTemplate" class="top-menu-action languageSelector"
          :class='{"active": reportConfig != null && activeLanguageDisplay != reportConfig.languageFallback}' @click='activeLanguageSwitching = !activeLanguageSwitching'>
          <div class="languageSelectorSelection" v-if="activeLanguageSwitching">
            <div class="languageSelectorButton"
            :class='{"active": reportConfig != null && activeLanguageDisplay != reportConfig.languageFallback,
            "selected": reportConfig != null && activeLanguageDisplay == reportConfig.languageFallback}'
            @click.stop="activeLanguageDisplay = reportConfig.languageFallback; activeLanguageSwitching = !activeLanguageSwitching;">
              {{ $t('editor.fallbackLanguage') }} ({{ reportConfig.languageFallback.toUpperCase() }})
            </div>
            <div v-for="(language, idx) in reportConfig.languages" :key="idx" class="languageSelectorButtonHull"
            @click.stop="activeLanguageDisplay = language; activeLanguageSwitching = !activeLanguageSwitching;">
              <div v-if="language != reportConfig.languageFallback" class="languageSelectorButton"
              :class='{"active": activeLanguageDisplay != reportConfig.languageFallback, "selected": activeLanguageDisplay == language}'>
                {{ language.toUpperCase() }}
              </div>
            </div>
          </div>
          <div class="languageSelectorSelected" v-if="!activeLanguageSwitching">
            {{ reportConfig != null && activeLanguageDisplay != reportConfig.languageFallback ? activeLanguageDisplay.toUpperCase() : '' }}
          </div> <i class="fas fa-language"></i> <span class='actionTitle'>{{ $t('editor.language') }}</span>
        </div>
        <div v-if="reportConfig != null && !reportConfig.phoneOnly" class="editorSelector">
          <div class='editorSelectorBar' :class='{"active": currentEditor == 0}' @click='switchEditor(0)'><i class="fas fa-tablet-alt"></i> {{ $t('editor.tablet') }}</div>
          <div class='editorSelectorBar' :class='{"active": currentEditor == 1}' @click='switchEditor(1)'><i class="fas fa-mobile-alt"></i> {{ $t('editor.phone') }}</div>
        </div>
      </div>
      <ToolbarComponent
        :isTemplate="isTemplate"
        :sharedUndoStack="undoStack"
        :currentEditor = "currentEditor"
        :fieldTypeDisables = "fieldTypeDisables"

        @storeToBackend="storeToBackend"
        @duplicateField="onDuplicate"
        @deleteField="onDelete"
        @addVerticalAuxLine="addVerticalAuxLine"
        @addHorizontalAuxLine="addHorizontalAuxLine"
        @undo="undo"
        @redo="redo"
        @moveForwards="moveForwards"
        @moveBackwards="moveBackwards"
        @addNewFieldWithType="addNewFieldWithType"
        @cloneTemplate="cloneTemplate"
      />

      <div class="editors">
        <TabletEditorComponent v-show="reportConfig != null && currentEditor == 0" ref="tabletEditor"
          :isTemplate="isTemplate"
          :sharedActiveLanguage="activeLanguageDisplay"
          :loadedReportConfig="reportConfig"
          :sharedImageDict="imageDict"
          :sharedFields="fields"
          :sharedActiveFields="activeFields"
          @resetActiveLanguage="activeLanguageDisplay = reportConfig.languageFallback; activeLanguageSwitching = false"
          @update:sharedActiveFields="activeFields = $event"
          @addedFieldConfig="addedFieldConfig"
          @storeUndoStep="storeUndoStep"
          @sendToAiAgent="sendToAIAgent"
        />
        <PhoneEditorComponent  v-show="reportConfig != null && currentEditor == 1" ref="phoneEditor"
          :isTemplate="isTemplate"
          :sharedActiveLanguage="activeLanguageDisplay"
          :loadedReportConfig="reportConfig"
          :sharedImageDict="imageDict"
          :sharedFields="fields"
          :sharedActiveFields="activeFields"
          @resetActiveLanguage="activeLanguageDisplay = reportConfig.languageFallback; activeLanguageSwitching = false"
          @update:sharedActiveFields="activeFields = $event"
          @addedFieldConfig="addedFieldConfig"
          @storeUndoStep="storeUndoStep"
        />
      </div>
    </div>
    <div v-if="backendOperation != null && !noAccessRights">
      <div v-if="backendOperation === 'aiThinking'">
        <AIThinkingAnimationComponent/>
      </div>
      <div v-else>{{backendOperation}}</div>
    </div>
    <div v-if="noAccessRights">
      <br/>
      {{$t('message.noAccessRights')}}<br/><br/>
      <button @click="$router.go(-1)">{{$t('general.back')}}</button>
    </div>
    <canvas id="imageConverterCanvas"></canvas>
    <PromptModal ref="templateDescModal" :title="$t('modal.titleTemplateDesc')" :text="$t('modal.textTemplateDesc')"/>
    <ConfirmModal ref="confirmLeaveEditor"  :title="$t('modal.titleLeaveEditor')" :text="$t('modal.textLeaveEditor')"/>
  </div>
</template>

<script>
/* eslint-disable no-console, no-underscore-dangle, no-alert */

import { v4 as uuidv4 } from 'uuid';
import Constants from '@/helpers/Constants';
import CryptoHelper from '@/helpers/CryptoHelper';
import ServiceHelper from '@/helpers/ServiceHelper';
import TabletEditorComponent from '@/components/editor/TabletEditorComponent.vue';
import PhoneEditorComponent from '@/components/editor/PhoneEditorComponent.vue';
import ToolbarComponent from '@/components/ToolbarComponent.vue';
import PromptModal from '@/components/modals/PromptModal.vue';
import ConfirmModal from '@/components/modals/ConfirmModal.vue';
import EditableField from '@/helpers/EditableField';
import ImageField from '@/helpers/ImageField';
import DateField from '@/helpers/DateField';
import ListField from '@/helpers/ListField';
import TableField from '@/helpers/TableField';
import LanguageHelper from '@/helpers/LanguageHelper';
import AIThinkingAnimationComponent from '@/components/AIThinkingAnimationComponent.vue';

export default {
  name: 'EditorComponent',
  props: ['configIdToEdit', 'isTemplateProp'],
  components: {
    TabletEditorComponent,
    PhoneEditorComponent,
    ToolbarComponent,
    PromptModal,
    ConfirmModal,
    AIThinkingAnimationComponent,
  },
  data() {
    return {
      tenant: this.$store.getters.tenant,
      configId: null,
      reportConfig: null,
      currentEditor: 0,
      imageDict: {},
      fields: [],
      undoStack: {
        steps: [],
        undoPos: 0,
      },
      noAccessRights: false,
      activeFields: [],
      backendOperation: null,
      activeLanguageDisplay: LanguageHelper.getBrowserLocale(),
      activeLanguageSwitching: false,
      isTemplate: false,
    };
  },
  watch: {
    phoneOnly(newVal) {
      console.log('watch phoneOnly', newVal, this.reportConfig);
      if (newVal && this.reportConfig != null) {
        this.currentEditor = 1;
        this.getCurrentEditorComponent().update(this.reportConfig);
      }
    },
  },
  mounted() {
    this.isTemplate = this.isTemplateProp;
    window.addEventListener('copy', this.onCopy);
    window.addEventListener('paste', this.onPaste);

    this.configId = this.configIdToEdit;
    this.loadConfig();
  },
  unmounted() {
    this.undoStack = {
      steps: [],
      undoPos: 0,
    };
    this.fields = [];
    this.imageDict = {};
  },
  computed: {
    phoneOnly() {
      return this.reportConfig != null && this.reportConfig.phoneOnly;
    },
    fieldTypeDisables() {
      const disables = {};
      if (this.reportConfig != null) {
        this.reportConfig.fields.forEach((field) => {
          if (field.type === Constants.FIELD_TYPES.reference) {
            disables[Constants.FIELD_TYPES.reference] = true;
          }
        });
      }
      // console.log('disables ', disables);
      return disables;
    },
  },
  methods: {
    async goBack() {
      if (this.isTemplate) {
        this.$router.go(-1);
      } else if ((await this.$refs.confirmLeaveEditor.show())) {
        this.$router.go(-1);
      }
    },
    switchEditor(idx) {
      this.reportConfig = this.getCurrentEditorComponent().getReportConfig();
      this.currentEditor = idx;
      this.getCurrentEditorComponent().update(this.reportConfig);
    },
    initEditor(loadedReportConfig) {
      const reportConfig = loadedReportConfig;
      if (loadedReportConfig == null) return;
      this.activeLanguageDisplay = reportConfig != null ? reportConfig.languageFallback : LanguageHelper.getBrowserLocale();
      // coming from iPad, the accessoryLines are a string
      try {
        reportConfig.accessoryLines = reportConfig.accessoryLines != null ? reportConfig.accessoryLines : [];
        if (typeof reportConfig.accessoryLines === 'string' || reportConfig.accessoryLines instanceof String) {
          reportConfig.accessoryLines = JSON.parse(reportConfig.accessoryLines);
        }
      } catch (e) {
        reportConfig.accessoryLines = [];
      }
      // coming from iPad, the pageNames are a string
      reportConfig.pageNames = reportConfig.pageNames != null ? reportConfig.pageNames : [];
      if (typeof reportConfig.pageNames === 'string' || reportConfig.pageNames instanceof String) {
        reportConfig.pageNames = JSON.parse(reportConfig.pageNames);
      }

      if (reportConfig.lifetimeFieldCount == null) {
        reportConfig.lifetimeFieldCount = reportConfig.fields.length + 1;
      }

      this.fields = [];
      let idx = 0;
      reportConfig.fields.forEach((field) => {
        if (field.fieldIdx == null) {
          const f = field;
          f.fieldIdx = idx;
        }
        idx += 1;
        if (field.type === Constants.FIELD_TYPES.image) {
          // image
          const cachedImg = this.imageDict[`${field.name}`];
          if (cachedImg != null) {
            const imageField = new ImageField(field, cachedImg);
            this.fields.push(imageField);
          } else {
            const pastedImage = new Image();
            const imageField = new ImageField(field, pastedImage);
            this.fields.push(imageField);
            this.imageDict[`${field.name}`] = pastedImage;
            pastedImage.onload = () => {
              imageField.setImage(pastedImage, this.context);
              imageField.config.special = null;
            };
            try {
              const b64imgData = JSON.parse(field.special).imgData;
              pastedImage.src = `data:image/jpg;base64, ${b64imgData}`;
            } catch (e) {
              console.error(e);
            }
            this.imageDict[field.name] = pastedImage;
          }
        } else if (field.type === Constants.FIELD_TYPES.date) {
          this.fields.push(new DateField(field));
        } else if (field.type === Constants.FIELD_TYPES.list) {
          this.fields.push(new ListField(field, this.$t));
        } else if (field.type === Constants.FIELD_TYPES.table) {
          this.fields.push(new TableField(field, this.$t));
        } else {
          this.fields.push(new EditableField(field));
        }

        // convert condition JSON string into conditionObj
        if (field.conditionJSON != null) {
          const f = field;
          try {
            f.condition = JSON.parse(f.conditionJSON);
          } catch (e) {
            console.error(e);
          }
        }
      });
      this.reportConfig = reportConfig;
    },
    getCurrentEditorComponent() {
      return this.currentEditor === 0 ? this.$refs.tabletEditor : this.$refs.phoneEditor;
    },
    addVerticalAuxLine() {
      if (this.getCurrentEditorComponent().addVerticalAuxLine) this.getCurrentEditorComponent().addVerticalAuxLine();
    },
    addHorizontalAuxLine() {
      if (this.getCurrentEditorComponent().addHorizontalAuxLine) this.getCurrentEditorComponent().addHorizontalAuxLine();
    },
    moveForwards() {
      if (this.getCurrentEditorComponent().moveForwards) this.getCurrentEditorComponent().moveForwards();
    },
    moveBackwards() {
      if (this.getCurrentEditorComponent().moveBackwards) this.getCurrentEditorComponent().moveBackwards();
    },
    addNewFieldWithType(typeId) {
      if (this.getCurrentEditorComponent().addNewFieldWithType) {
        const newConfig = this.getCurrentEditorComponent().addNewFieldWithType(typeId);
        this.initEditor(newConfig);
      }
    },
    addedFieldConfig(c, reportConfig) {
      if (this.currentEditor === 1 && c.type === Constants.FIELD_TYPES.image) {
        // image added in phone editor
        this.initEditor(reportConfig);
      }
      // this.reportConfig = JSON.parse(JSON.stringify(this.reportConfig));
    },
    getNextFreeName() {
      this.reportConfig.lifetimeFieldCount += 1;
      // search for unused field name
      const newName = `Feld_${Constants.lpad('0', 3, this.reportConfig.lifetimeFieldCount)}`;
      // search for unused field title
      const newTitle = `Titel_${Constants.lpad('0', 3, this.reportConfig.lifetimeFieldCount)}`;
      return { newName, newTitle };
    },
    onDelete() {
      if (this.activeFields != null && this.activeFields.length > 0) {
        this.activeFields.forEach((a) => {
          this.fields = this.fields.filter((f) => f !== a);
          this.reportConfig.fields = this.reportConfig.fields.filter((f) => f !== a.config);
        });
        this.activeFields = [];
        this.storeUndoStep();
      }
    },
    onCopy(e) {
      if (this.activeFields != null && this.activeFields.length > 0) {
        const copiedConfigs = [];
        this.activeFields.forEach((a) => {
          copiedConfigs.push(JSON.parse(JSON.stringify(a.config)));
        });
        if (e && e.clipboardData) {
          e.clipboardData.setData('application/json', JSON.stringify(copiedConfigs));
          e.preventDefault();
        }
      }
    },
    onDuplicate() {
      const newFields = [];
      if (this.activeFields != null && this.activeFields.length > 0) {
        this.activeFields.forEach((field) => {
          const { newName } = this.getNextFreeName();

          const newConfig = JSON.parse(JSON.stringify(field.config));
          newConfig.name = newName;
          newConfig.x += 10;
          newConfig.y += 10;
          this.reportConfig.lifetimeFieldCount += 1;
          newConfig.fieldIdx = this.reportConfig.lifetimeFieldCount;
          let f = null;
          if (field.config.type === Constants.FIELD_TYPES.image) {
            // image
            f = new ImageField(newConfig, field.image);
            this.imageDict[`${newName}`] = field.image;
            this.reportConfig.fields.push(newConfig);
            this.fields.push(f);
          } else {
            f = new EditableField(newConfig);
            this.fields.push(f);
          }
          newFields.push(f);
          this.storeUndoStep();
          this.reportConfig.fields.push(newConfig);
          newConfig.phoneOrderNumber = this.reportConfig.fields.length;
        });
        this.activeFields = newFields;
        this.initEditor(this.reportConfig);
      }
    },
    // TODO add onCut
    onPaste(e) {
      try {
        const copiedConfigs = JSON.parse(e.clipboardData.getData('application/json'));
        if (copiedConfigs != null && copiedConfigs.length > 0) {
          this.activeFields = [];
          copiedConfigs.forEach((config) => {
            const newConfig = config;
            const { newName } = this.getNextFreeName();
            newConfig.name = newName;
            this.reportConfig.lifetimeFieldCount += 1;
            newConfig.phoneOrderNumber = this.reportConfig.fields.length;
            newConfig.fieldIdx = this.reportConfig.lifetimeFieldCount;
            if (this.currentEditor === 0) {
              newConfig.page = this.getCurrentEditorComponent(this.currentEditor).getActivePage();
            }
            let f = null;
            if (newConfig.type === Constants.FIELD_TYPES.image) {
              // image
              f = new ImageField(newConfig, newConfig.image);
              this.imageDict[`${newName}`] = newConfig.image;
              this.fields.push(f);
            } else {
              f = new EditableField(newConfig);
              this.fields.push(f);
            }
            this.reportConfig.fields.push(newConfig);
            this.activeFields.push(f);
          });

          this.storeUndoStep();
          this.initEditor(this.reportConfig);

          e.stopPropagation();
        }
      } catch (err) {
        // comment needed
      }
    },
    storeUndoStep() {
      if (this.undoStack.steps.length === Constants.MAX_UNDO_STEPS) this.undoStack.steps.splice(0, 1);
      if (this.undoStack.undoPos < this.undoStack.steps.length - 1) {
        this.undoStack.steps = this.undoStack.steps.splice(0, this.undoStack.undoPos + 1);
      }
      this.undoStack.steps.push(JSON.stringify(this.reportConfig));
      this.undoStack.undoPos = this.undoStack.steps.length - 1;
    },
    undo() {
      if (this.undoStack.undoPos === this.undoStack.steps.length) {
        // this.undoStack.push(JSON.parse(JSON.stringify(this.reportConfig)));
        this.undoStack.undoPos -= 1;
      }
      if (this.undoStack.undoPos > 0) {
        this.undoStack.undoPos -= 1;
        this.reportConfig = JSON.parse(this.undoStack.steps[this.undoStack.undoPos]);
        this.initEditor(this.reportConfig);
      }
    },
    redo() {
      if (this.undoStack.undoPos < this.undoStack.steps.length - 1) {
        this.undoStack.undoPos += 1;
        this.reportConfig = JSON.parse(this.undoStack.steps[this.undoStack.undoPos]);
        this.initEditor(this.reportConfig);
      }
    },
    createNewReportConfig() {
      return {
        name: `${this.$t('editor.unnamedForm')} ${Constants.getFormattedUTCTimeForMySQL(new Date())}`,
        fields: [],
        nrOfPages: 1,
        pageNames: [`${this.$t('editor.page')} 1`],
        timeOfCreation: Constants.getFormattedUTCTimeForMySQL(new Date()),
        timeOfModification: Constants.getFormattedUTCTimeForMySQL(new Date()),
        entity: 'ReportConfig',
        id: `/reportConfig/${uuidv4()}`,
        _id: '_new',
        lifetimeFieldCount: 0,
        languages: ['de', 'en', 'fr', 'es', 'pt'],
        languageFallback: LanguageHelper.getBrowserLocale(),
      };
    },
    async loadConfig() {
      if (this.configId === '_new') {
        // create new report!
        this.reportConfig = this.createNewReportConfig();
        this.storeUndoStep();
      } else {
        this.backendOperation = this.$t('message.loading');
        try {
          if (this.isTemplate) {
            this.loadTemplate();
          } else {
            this.loadNormalConfig();
          }
        } catch (error) {
          this.errorMessage = error;
          this.backendOperation = null;
          console.error('There was an error!', error);
        }
      }
      return null;
    },
    async prepareImages() {
      const vm = this;
      return new Promise((resolve, reject) => {
        try {
          vm.reportConfig.fields.forEach((f) => {
            const field = f;
            if (field.type === Constants.FIELD_TYPES.image) {
              field.special = JSON.stringify({
                imgData: ImageField.getBase64Data(vm.imageDict[f.name]),
              });
            }
          });
          resolve();
        } catch (err) {
          console.error(err);
          reject(err);
        }
      });
    },
    async sendToAIAgent(prompt, model) {
      try {
        this.backendOperation = 'aiThinking';

        const token = ServiceHelper.getToken();

        const requestOptionsSend = {
          method: 'POST',
          timeout: 60000,
          headers: {
            'Content-Type': 'application/json',
            coladatoken: token,
            coladaproduct: 'web',
            deviceuuid: ServiceHelper.getDeviceUuid(),
            devicename: navigator.userAgent,
            devicetype: 'BROWSER',
            tenant: ServiceHelper.getTenant(),
            coladaserver: ServiceHelper.getServerForTenant(),
          },
          body: JSON.stringify({ prompt, model, content: this.reportConfig.fields }),
        };

        const url = `${process.env.VUE_APP_COLADA_BASE_URL}/toAiAgent`;
        console.log(`USING URL ${url} with method ${requestOptionsSend.method}`);

        fetch(url, requestOptionsSend)
          .then(async (response) => {
            const data = await response.json();
            this.backendOperation = null;
            if (!response.ok) {
              // get error message from body or default to response status
              const error = data.msg;
              this.errorMessage = error;
              if (data.errorCode != null) {
                alert(this.$t(`error.code_${data.errorCode}`));
              }
              return error;
            }
            // eslint-disable-next-line no-underscore-dangle
            this.storeUndoStep();
            const newReportConfig = JSON.parse(JSON.stringify(this.reportConfig));
            newReportConfig.fields = data.payload;
            console.log(newReportConfig);
            this.initEditor(newReportConfig);

            return null;
          })
          .catch((error) => {
            this.backendOperation = null;
            this.errorMessage = error;
            console.error('There was an error!', error);
            this.loggedIn = false;
            this.credentials = null;
          });
      } catch (err) {
        console.error('GOT ERROR', err);
        this.backendOperation = null;
      }
    },
    async storeToBackend() {
      this.backendOperation = this.$t('message.saving');
      // get image data
      try {
        await this.prepareImages();

        // console.log('all prepared!', JSON.stringify(this.reportConfig));
        this.reportConfig.timeOfModification = Constants.getFormattedUTCTimeForMySQL(new Date());

        // we have to convert "accessoryLines" and "pageName" to JSON-Strings within the config so that the current
        // iPad version can handle them.
        const reportConfigToSend = JSON.parse(JSON.stringify(this.reportConfig));
        reportConfigToSend.accessoryLines = JSON.stringify(this.reportConfig.accessoryLines);
        reportConfigToSend.pageNames = JSON.stringify(this.reportConfig.pageNames);

        // convert condition objs to JSON strings
        reportConfigToSend.fields.forEach((field) => {
          if (field.condition != null) {
            const f = field;
            try {
              f.conditionJSON = JSON.stringify(f.condition);
            } catch (e) {
              console.error(e);
            }
            delete f.condition;
          }
        });

        const token = ServiceHelper.getToken();
        const encryptedContent = CryptoHelper.encrypt(Buffer.from(JSON.stringify(reportConfigToSend)));
        const encryptedTitle = CryptoHelper.encrypt(Buffer.from(reportConfigToSend.name));

        const currentId = this.configId;

        const requestOptionsSend = {
          method: currentId === '_new' ? 'POST' : 'PUT',
          headers: {
            'Content-Type': 'application/json',
            coladatoken: token,
            coladaproduct: 'web',
            deviceuuid: ServiceHelper.getDeviceUuid(),
            devicename: navigator.userAgent,
            devicetype: 'BROWSER',
            tenant: ServiceHelper.getTenant(),
            coladaserver: ServiceHelper.getServerForTenant(),
          },
          body: JSON.stringify({ content: encryptedContent, name: encryptedTitle }),
        };

        const suffix = currentId === '_new' ? '' : `/${currentId}`;
        const url = `${process.env.VUE_APP_COLADA_BASE_URL}/reportConfig${suffix}`;
        console.log(`USING URL ${url} with method ${requestOptionsSend.method}`);

        fetch(url, requestOptionsSend)
          .then(async (response) => {
            const data = await response.json();
            this.backendOperation = null;
            if (!response.ok) {
              // get error message from body or default to response status
              const error = data.msg;
              this.errorMessage = error;
              if (data.errorCode != null) {
                alert(this.$t(`error.code_${data.errorCode}`));
              }
              return error;
            }
            // eslint-disable-next-line no-underscore-dangle
            this.reportConfig._id = data.payload._id;
            this.configId = data.payload._id;

            return null;
          })
          .catch((error) => {
            this.backendOperation = null;
            this.errorMessage = error;
            console.error('There was an error!', error);
            this.loggedIn = false;
            this.credentials = null;
          });
      } catch (err) {
        console.error('GOT ERROR', err);
        this.backendOperation = null;
      }
    },
    async loadNormalConfig() {
      try {
        const data = await ServiceHelper.loadDataFromBackend(`reportConfig/${this.configId}`);
        this.backendOperation = null;
        // check for error response
        const decrypted = CryptoHelper.decrypt(data.payload.content);
        const loadedConfig = JSON.parse(decrypted);
        if (data.payload.isEditable || this.$store.getters.userIsAdmin) {
          this.noAccessRights = false;
          this.initEditor(loadedConfig);
          this.storeUndoStep();
        } else {
          this.noAccessRights = true;
        }
      } catch (e) {
        console.error(e);
      }
    },
    async loadTemplate() {
      const data = await ServiceHelper.loadDataFromBackend(`template/${this.configId}`);
      this.backendOperation = null;
      // check for error response
      const loadedConfig = data.payload.content;
      // console.log(loadedConfig);
      this.noAccessRights = false;
      this.initEditor(loadedConfig);
    },
    async cloneTemplate() {
      const newConfig = this.createNewReportConfig();
      this.isTemplate = false;
      newConfig.fields = this.reportConfig.fields;
      newConfig.lifetimeFieldCount = this.reportConfig.lifetimeFieldCount;
      newConfig.nrOfPages = this.reportConfig.nrOfPages;
      if (newConfig.nrOfPages && this.reportConfig.pageName) {
        newConfig.nrOfPages.pageNames = [...this.reportConfig.pageNames];
      }

      this.configId = '_new';
      this.reportConfig = newConfig;
      this.initEditor();
    },
    async storeAsTemplate() {
      const templateDescription = await this.$refs.templateDescModal.show('');
      if (templateDescription == null || templateDescription.trim().length === 0) {
        return;
      }

      this.backendOperation = this.$t('message.saving');
      // get image data
      try {
        await this.prepareImages();
        this.reportConfig.timeOfModification = Constants.getFormattedUTCTimeForMySQL(new Date());
        const reportConfigToSend = JSON.parse(JSON.stringify(this.reportConfig));
        reportConfigToSend.pageNames = JSON.stringify(this.reportConfig.pageNames);
        reportConfigToSend.accessoryLines = JSON.stringify(this.reportConfig.accessoryLines);
        reportConfigToSend.fields.forEach((field) => {
          if (field.condition != null) {
            const f = field;
            try {
              f.conditionJSON = JSON.stringify(f.condition);
            } catch (e) {
              console.error(e);
            }
            delete f.condition;
          }
        });

        const token = ServiceHelper.getToken();
        const requestOptionsSend = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            coladatoken: token,
            coladaproduct: 'web',
            deviceuuid: ServiceHelper.getDeviceUuid(),
            devicename: navigator.userAgent,
            devicetype: 'BROWSER',
            tenant: ServiceHelper.getTenant(),
            coladaserver: ServiceHelper.getServerForTenant(),
          },
          body: JSON.stringify({ content: reportConfigToSend, name: reportConfigToSend.name, description: templateDescription }),
        };

        const url = `${process.env.VUE_APP_COLADA_BASE_URL}/template`;
        fetch(url, requestOptionsSend)
          .then(async (response) => {
            const data = await response.json();
            this.backendOperation = null;
            if (!response.ok) {
              // get error message from body or default to response status
              const error = data.msg;
              this.errorMessage = error;
              return error;
            }
            return null;
          })
          .catch((error) => {
            this.backendOperation = null;
            this.errorMessage = error;
            console.error('There was an error!', error);
            this.loggedIn = false;
            this.credentials = null;
          });
      } catch (err) {
        console.error(err);
        this.backendOperation = null;
      }
    },
  },
};
</script>

<style scoped>
.top-menu {
  text-align: left;
  margin: 0px 0px;
  background-color: var(--color_almost_black);
  color: white;
  display: flex;
  justify-content: space-between;
  vertical-align: middle;
  padding: 14px 10px;
  min-width: 500px;
  overflow-x: auto;
}

.top-menu a {
  color: white;
  text-decoration: none;
}

.top-menu-action {
  cursor: pointer;
  font-size: 18px;
  line-height: 23px;
  padding: 10px 15px 0px;
  border-radius: 20px;
  text-align: center;
  vertical-align: middle;
}

.top-menu-action:hover {
  background: white;
  color: var(--color_almost_black);
  box-shadow: 1px 1px 2px rgba(0,0,0, 0.3);
}

.languageSelector {
  color: white;
  cursor: pointer;
  padding: 10px 15px;
  margin: 0px 1px;
  border-radius: 20px;
  font-weight: 500;
}
.languageSelector.active {
  background: white;
  color: var(--color_almost_black);
}
.languageSelectorSelection {
  margin: -1px 0px -1px -10px;
  display: inline-block;
}
.languageSelectorSelected {
  display: inline-block;
}
.languageSelectorButtonHull {
  display: inline-block;
}
.languageSelectorButton {
  display: inline-block;
  padding: 0px 5px;
  margin: 0px 1px;
  border-radius: 15px;
  border: 2px solid white;
  background-color: var(--color_almost_black);
  color: white;
  font-size: 15px;
}
.languageSelector.selected {
  border: 2px solid white;
  background-color: white;
  color: var(--color_almost_black);
}
.languageSelectorButton.active {
  border: 2px solid var(--color_almost_black);
  background-color: white;
  color: var(--color_almost_black);
}
.languageSelectorButton.active.selected {
  border: 2px solid var(--color_almost_black);
  background-color: var(--color_almost_black);
  color: white;
}

.editorSelector {
  background-color: #171411;
  border-radius: 20px;
  padding: 0px 0px;
  height: 36px;
  margin-top: 4px;
}

.editorSelectorBar {
  display: inline-block;
  color: white;
  cursor: pointer;
  padding: 6px 15px;
  margin: 0px 1px;
  border-radius: 20px;
  font-weight: 500;
}
.editorSelectorBar.active {
  background: white;
  color: var(--color_almost_black);
  cursor: default;
}

.editors {
  position: relative;
  background: #f8f8fb;
  height: 100%;
  min-width: 700px;
  overflow-x: auto;
}

#imageConverterCanvas {
  display: none;
}

::v-deep(.rightSideBar) {
  border: var(--color_orange) solid 4px;
  border-radius: 0px 0px 12px 12px;
  box-shadow: 4px 4px 8px lightgray;
  background: white;
  position: fixed;
  right: 0px;
  top: 136px;
  overflow-y: auto;
  overflow-x: visible;
  z-index: 999;
  padding: 0px;
  max-height: calc(100% - 200px);
  transition: right ease-in-out .4s;
}

::v-deep(.rightSideBar.phone) {
  max-height: calc(100% - 160px);
}
::v-deep(.rightSideBar.hidden) {
  right: -270px;
}

::v-deep(.rightSideBar .toggleButton) {
  display: inline-block;
  margin-right: 8px;
  cursor: pointer;
}

::v-deep(.inspector) {
  width: 300px;
  vertical-align: top;
  margin-bottom: 16px;
}

::v-deep(.inspector .title) {
  font-weight: 800;
  padding: 4px 0px;
  background-color: var(--color_orange);
  color: white;
  padding-left: 8px;
  margin-bottom: 8px;
  cursor: default;
}

::v-deep(.inspector .content) {
  padding: 8px;
}

::v-deep(.inspector .content input[type='text']),
::v-deep(.inspector .content input[type='number']),
::v-deep(.inspector .content select),
::v-deep(.inspector .content input[type='range'])
{
  width: calc(100% - 12px);
  border: 1px solid var(--color_almost_black);
  background: white;
  padding: 4px;
  line-height: 20px;
  border-radius: 4px;
  margin-bottom: 8px;
  min-height: 20px;
}

::v-deep(.inspector .select-wrapper) {
  position: relative;
}

::v-deep(.inspector .content select) {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  outline: none;
  width: calc(100% - 2px);
  background-color: transparent;
}

::v-deep(.select-wrapper::before) {
  color: #B7ACA6;
  content: "∟";
  font-size: 18px;
  position: absolute;
  font-weight: bold;
  right: 10px;
  top: 0px;
  z-index: -1;
  transform: rotate(-45deg);
}

::v-deep(.inspector .content input[type='text'].disabled) {
  background: #e5e5e5;
}

::v-deep(.inspector .content input[type='text'].hasPicker) {
  width: calc(100% - 50px);
}

::v-deep(.widget) {
  display: inline-block;
  text-align: left;
  border-radius: 2px;
}

::v-deep(.inspector .reportConfigName) {
  font-weight: 500;
  font-size: 1.5em;
}

.editorComponentWrapper, .editorComponent {
  position: relative;
  height: 100%;
}

@media screen and (max-width: 1300px) {
  .top-menu-action .actionTitle {
    display: none;
  }
}

.rowLabel, ::v-deep(.rowLabel) {
  width: 100%;
  position: relative;
  margin-bottom: 2px;
}

.rowLabel .popper, ::v-deep(.rowLabel .popper) {
  max-width: 240px;
  width: 240px;
}

</style>
