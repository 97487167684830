<template>
  <LottieAnimationComponent
    :animationData="require(`@/assets/animations/ai_thinking_animation_1.json`)"
    :text="$t('editor.aiThinking')"
  />
</template>
<script>
import LottieAnimationComponent from './LottieAnimationComponent.vue';

export default {
  name: 'AIThinkingAnimationComponent',
  components: {
    LottieAnimationComponent,
  },
};
</script>
<style scoped>
</style>
