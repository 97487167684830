<template>
  <div class="tabletEditorComponent" ref="containerRoot">
    <!--
    <div style="position: fixed; left: 400px; top: 50px; color: white; z-index:999" v-if="mouseInfo != null">{{ activeLine + ' ' + mouseInfo.x + ' ' + mouseInfo.y + ' ' + mouseInfo.rawX + ' '
      + mouseInfo.btn1 + ' ' + mouseInfo.isTouchEvent + ' ' }}  CO {{ $refs.editorCanvas.offsetLeft }} {{canvasOffset != null ? canvasOffset.x : ''}} </div>
      -->
    <div class="editorArea" ref="editorArea">
      <RulerComponent :mousePos="mouseInfo" :pageSize="pageSize" id="horizontalRuler" :marked="sharedActiveFields"></RulerComponent>
      <RulerComponent orientation="v" :mousePos="mouseInfo" :pageSize="pageSize" id="verticalRuler" :marked="sharedActiveFields"></RulerComponent>
      <canvas oncontextmenu="return false;" id="editorCanvas" ref="editorCanvas"></canvas>
    </div>
    <div class="pageSelector" ref="pageSelector" v-if="reportConfig != null">
      <div class="pageLink" :class="{'active': pageNr-1 == currentPage}"
        v-for="pageNr in reportConfig.nrOfPages"
        :key="pageNr"
        @click="currentPage = (pageNr - 1); updateCanvas()"
        >{{(reportConfig.pageNames[pageNr-1] != null && reportConfig.pageNames[pageNr-1].trim() != '') ?
          reportConfig.pageNames[pageNr-1] : `Seite ${pageNr}`}}
          <!--<span class="edit" @click="editPage(pageNr-1, $event)">✏️</span>&nbsp;&nbsp;-->&nbsp;
          <span v-if="reportConfig.nrOfPages > 1" class="remove" @click="removePage(pageNr-1, $event)"><i class="fas fa-trash"></i></span>
      </div>
      <div v-if="reportConfig.nrOfPages < maxPages" class="pageLink" @click="addPage()"><i class="fas fa-plus-square"></i> Add</div>
    </div>
    <div class="rightSideBar" :class="{hidden: sideBarHidden}" v-if="reportConfig != null">
      <div class="widget inspector">
        <div class="title" @click="toggleSideBar()"><div class="toggleButton"><i class="fas fa-arrows-alt-h"/></div>{{ $t('editor.inspector.general') }}</div>
        <div class="content">
          <div class="rowLabel">{{ $t('editor.inspector.name') }}:<HelpOverlay :content="$t('editor.inspector.hints.name')"/></div>
          <div class="reportConfigName">{{reportConfig.name}} <span data-cy="inspector-edit-name" v-if="!isTemplate" class="edit" @click="editReportName()">
            <i class="fas fa-pen"></i> </span></div>
          <div class="rowLabel">{{ $t('editor.inspector.languages') }}:
              <button class="dropArrow" @click="limitLanguages = !limitLanguages">
              <div v-if="!limitLanguages"><i class="fas fa-angle-down"/></div>
              <div v-if="limitLanguages"><i class="fas fa-angle-up"/></div>
            </button>
            <HelpOverlay :content="$t('editor.inspector.hints.languages')"/>
          </div>
          <div v-if="limitLanguages">
            <div v-for="(name, code) in languages" :key="code" :class="{'disabled': code == reportConfig.languageFallback}">
              &nbsp;&nbsp;<input type="checkbox" :checked="reportConfig.languages.indexOf(code) > -1" :disabled="isTemplate || code == reportConfig.languageFallback"
              @change="changeLanguage(code, $event); $emit('resetActiveLanguage')" /> {{ name }} ({{ code.toUpperCase() }})
            </div>
            <div class="rowLabel">{{ $t('editor.inspector.fallbackLanguage') }}:<HelpOverlay :content="$t('editor.inspector.hints.fallback')"/></div>
            <div class="select-wrapper">
              <select :disabled="isTemplate" v-model="reportConfig.languageFallback" @change="updateCanvas(); $emit('resetActiveLanguage')">
                <option v-for='(language, idx) in reportConfig.languages' :key="idx" :value="language">{{ languages[language] }} ({{ language.toUpperCase() }})</option>
              </select>
            </div>
          </div>
          <hr/>
          <div>
            <div class="rowLabel">Talk to AI Agent</div>
            <textarea v-model="aiAgentPrompt" style="width: 100%; height: 100px;"/>
            <select v-model="aiModel">
              <option value="gpt35">GPT 3.5</option>
              <option value="gpt4o">GPT 4o</option>
              <option value="claude37">Claude 3.7 Sonnet</option>
              <option value="llama3">LLama 3 (local)</option>
              <option value="deepseek-r1-7b">Deepseek:R1 7B (local)</option>
            </select>
            <button @click="$emit('sendToAiAgent', aiAgentPrompt, aiModel)">Send</button>
          </div>
          <hr/>
          <!-- phone only setting-->
          <div class="rowLabel"><input type="checkbox" v-model="reportConfig.phoneOnly"/> {{ $t('editor.inspector.phoneOnly') }}
            <HelpOverlay :content="$t('editor.inspector.hints.phoneOnly')"/>
          </div>
        </div>
      </div>
      <br/>
      <div class="widget inspector">
        <div class="title">{{ $t('editor.inspector.view') }}</div>
        <div class="content">
          <div class="rowLabel">{{ $t('editor.inspector.gridSize') }}:<HelpOverlay :content="$t('editor.inspector.hints.gridSize')"/></div>
          <input :disabled="isTemplate" type="number" v-model="gridSize" @change="gridSize = parseInt(gridSize); updateCanvas()"/><br/>
          <div class="rowLabel"><input :disabled="isTemplate" type="checkbox" v-model="snapToGrid"/>&nbsp;{{ $t('editor.inspector.grid') }}<HelpOverlay :content="$t('editor.inspector.hints.grid')"/>
          </div>
          <div class="rowLabel"><input :disabled="isTemplate" type="checkbox" v-model="snapToAuxLines" @change="updateCanvas()"/>&nbsp;{{ $t('editor.inspector.auxLines') }}<HelpOverlay :content="$t('editor.inspector.hints.auxLines')"/>
          </div>
        </div>
      </div>
      <br/>
      <InspectorComponent v-if="reportConfig != null"
        :isTemplate="isTemplate"
        :currentFields="sharedActiveFields"
        :nrOfPages="reportConfig.nrOfPages"
        :reportConfig="reportConfig"
        @updateCanvas="updateCanvas(); storeInUndoStack()"
        @showValueInputPrompt="showValueInputPrompt"
        @showColumnEditDialog="showColumnEditDialog"
      /><br/>
      <!--
      <div class="widget inspector" v-if="reportConfig != null && activeField == null">
        <div class="fieldWrapper" v-for='(f, idx) in reportConfig.fields' :key='f.name'>
          <div class="title">{{idx + 1}}: {{fieldType(f.type)}} - {{f.title}}</div>
        </div>
      </div>
      -->
    </div>
    <PromptModal ref="renameFormModal" :title="$t('modal.titleReportConfigName')" :text="$t('modal.textReportConfigName')"/>
    <PromptModal ref="renamePageModal" :title="$t('modal.titlePageName')" :text="$t('modal.textPageName')"/>
    <PromptModal ref="editValueModal" :title="$t('modal.titleDropDownValueName')" :text="$t('modal.textDropDownValueName')"/>
    <CreateOrEditColumnModal ref="editColumnModal"/>
  </div>
</template>

<script>
/* eslint-disable no-console */

import EditableField from '@/helpers/EditableField';
import ImageField from '@/helpers/ImageField';
import ListField from '@/helpers/ListField';
import TableField from '@/helpers/TableField';
import DateField from '@/helpers/DateField';
import InspectorComponent from '@/components/editor/inspectors/InspectorComponent.vue';
import CreateOrEditColumnModal from '@/components/modals/CreateOrEditColumnModal.vue';
import RulerComponent from '@/components/RulerComponent.vue';
import ReportConfigControllerMixin from '@/mixins/ReportConfigControllerMixin.vue';
import LanguageHelper from '@/helpers/LanguageHelper';
import HelpOverlay from '@/components/HelpOverlay.vue';
import Constants from '@/helpers/Constants';

export default {
  name: 'EditorComponent',
  props: ['configId', 'loadedReportConfig', 'sharedImageDict', 'sharedUndoStack', 'sharedFields', 'sharedActiveFields', 'sharedActiveLanguage', 'isTemplate'],
  components: {
    InspectorComponent,
    RulerComponent,
    CreateOrEditColumnModal,
    HelpOverlay,
  },
  mixins: [ReportConfigControllerMixin],
  data() {
    return {
      aiAgentPrompt: null,
      aiModel: 'gpt35',
      canvas: null,
      context: null,
      // eslint-disable-next-line max-len
      mouseInfo: null,
      transformationsOngoing: [],
      gridSize: 20,
      snapToGrid: false,
      snapToAuxLines: true,
      showAuxLines: true,
      currentPage: 0,
      fields: [],
      loading: false,
      editTitleAction: false,
      activeFields: [],
      limitLanguages: false,
      languages: LanguageHelper.getStandardReportLanguages(),
      canvasOffset: null,
    };
  },
  watch: {
    snapToGrid() {
      this.updateCanvas();
    },
    sharedActiveFields: {
      handler(newVal) {
        this.activeFields = newVal;
      },
      immediate: true,
      deep: true,
    },
    sharedImageDict: {
      handler() {
        if (this.context != null) {
          this.updateCanvas();
        }
      },
      deep: true,
    },
    sharedActiveLanguage: {
      handler() {
        this.updateCanvas();
      },
      deep: true,
    },
  },
  mounted() {
    this.activeFields = this.sharedActiveFields;
  },
  methods: {
    init() {
      this.initCanvas();
      this.updateCanvas();
      setTimeout(() => {
        this.updateCanvas();
      }, 250);
    },
    update(rc) {
      this.reportConfig = rc;
      this.updateCanvas();
    },
    notifyActiveFields() {
      this.$emit('update:sharedActiveFields', this.activeFields);
    },
    calculatePageOffset(elem) {
      let currElem = elem;
      const offset = { x: 0, y: 0 };
      let cnt = 0;
      while (cnt < 100 && currElem.nodeName.toLowerCase() !== 'body') {
        offset.x += currElem.offsetLeft;
        offset.y += currElem.offsetTop;
        currElem = currElem.offsetParent;
        cnt += 1;
      }
      return offset;
    },
    changeLanguage(language, event) {
      const languages = [];
      Object.keys(this.languages).forEach((code) => {
        if ((code === language && event.target.checked) || (code !== language && this.reportConfig.languages.indexOf(code) > -1)) {
          languages.push(code);
        }
      });
      this.reportConfig.languages = languages;
      this.updateCanvas();
    },
    createHiPPICanvas(canvasToEdit, w, h) {
      const ratio = window.devicePixelRatio;
      const canvas = canvasToEdit;
      let ctx = null;
      canvas.width = w * ratio;
      canvas.height = h * ratio;
      canvas.style.width = `${w}px`;
      canvas.style.height = `${h}px`;
      ctx = canvas.getContext('2d');
      ctx.scale(ratio, ratio);
      return ctx;
    },
    // ******************************************************************************************************************************
    // Mouse Event Listeners
    // ******************************************************************************************************************************
    mouseDownListener(e) {
      e.preventDefault();
      this.getMouseInfoFromEvent(e, true, true);
      let hit = this.transformationsOngoing != null && this.transformationsOngoing.length !== 0;
      // eslint-disable-next-line vars-on-top
      for (let i = this.fields.length - 1; i >= 0; i -= 1) {
        const field = this.fields[i];
        if (!hit && field.isOnCurrentPage(this.currentPage) && field.checkMouseOver(this.mouseInfo)) {
          hit = true;
          this.editTitleAction = !e.shiftKey && !e.metaKey && !e.ctrlKey && this.activeFields.length === 1 && this.activeFields.indexOf(field) > -1;
          if (this.activeFields.indexOf(field) === -1 && !e.shiftKey && !e.metaKey && !e.ctrlKey) {
            this.activeFields = [field];
          } else if (this.activeFields.indexOf(field) === -1) {
            this.activeFields.push(field);
          } else if (e.shiftKey || e.metaKey || e.ctrlKey) {
            this.activeFields = this.activeFields.filter((a) => a !== field);
          }
          this.notifyActiveFields();
          break;
        }
      }
      if (!hit) {
        this.activeFields = [];
        this.notifyActiveFields();
        if (this.reportConfig != null && this.reportConfig.accessoryLines != null) {
          this.reportConfig.accessoryLines.forEach((l) => {
            const pos = l.POS;
            if (this.mouseInfo != null && l.TYPE === 'V' && Math.abs(this.mouseInfo.x - pos) < 3) {
              this.activeLine = l;
            } else if (this.mouseInfo != null && l.TYPE === 'H' && Math.abs(this.mouseInfo.y - pos) < 3) {
              this.activeLine = l;
            }
          });
        }
      }
      this.updateCanvas();
    },
    mouseMoveListener(e) {
      if (this.isTemplate) return;
      e.preventDefault();
      this.getMouseInfoFromEvent(e, null);
      if (e.touches) {
        this.movePointer({
          offsetX: this.mouseInfo.x,
          offsetY: this.mouseInfo.y,
        });
      } else {
        this.movePointer(e);
      }
    },
    mouseUpListener(e) {
      if (this.isTemplate) return;
      e.preventDefault();
      this.getMouseInfoFromEvent(e, false);
      if (!this.editTitleAction) {
        if (this.transformationsOngoing != null && this.transformationsOngoing.length !== 0) {
          this.storeInUndoStack();
        }
        this.transformationsOngoing = [];
        if (this.activeFields != null && this.activeFields.length > 0) {
          for (let i = 0; i < this.activeFields.length; i += 1) {
            this.activeFields[i].transformationOngoing = null;
          }
        }
        this.notifyActiveFields();
      } else if (this.activeFields != null && this.activeFields.length === 1) {
        // we switch over to edit title mode
        // eslint-disable-next-line no-alert
        const newTitle = prompt(this.$t('modal.labelTitle'),
          this.activeFields[0].config.title[this.sharedActiveLanguage] != null ? this.activeFields[0].config.title[this.sharedActiveLanguage] : this.activeFields[0].config.title['']);
        if (newTitle && newTitle.trim().length > 0) {
          this.storeInUndoStack();
          this.activeFields[0].config.title[this.sharedActiveLanguage] = newTitle;
        }
      }
      this.activeLine = null;
      this.updateCanvas();
      this.canvas.style.cursor = 'default';
    },
    getMouseInfoFromEvent(e, btnDown = null, setLastPos = false) {
      if (e.touches && e.touches.length > 0) {
        // touch device
        if (e.touches.length === 1) {
          const t = e.touches[0];
          // console.log(t);
          // if (this.canvasOffset == null) {
          this.canvasOffset = this.calculatePageOffset(e.target);
          // }
          this.mouseInfo = {
            ...(this.mouseInfo != null ? this.mouseInfo : {}),
            x: t.pageX - this.canvasOffset.x + this.$refs.containerRoot.scrollLeft,
            y: t.pageY - this.canvasOffset.y + this.$refs.containerRoot.scrollTop,
            rawX: t.pageX,
            isTouchEvent: true,
          };
          this.mouseInfo.btn1 = btnDown != null ? btnDown : this.mouseInfo.btn1; // && this.getMouseOverField() != null;
        }
      } else {
        this.mouseInfo = {
          ...(this.mouseInfo != null ? this.mouseInfo : {}),
          x: e.offsetX,
          y: e.offsetY,
          btn1: btnDown != null ? (btnDown && e.button === 0) : this.mouseInfo.btn1,
        };
      }
      if (setLastPos) {
        this.mouseInfo.lastX = this.mouseInfo.x;
        this.mouseInfo.lastY = this.mouseInfo.y;
      }
    },
    initCanvas() {
      if (this.canvas != null) return;
      this.canvas = document.getElementById('editorCanvas');
      this.canvas.style.width = `${this.pageSize.width}px`;
      this.canvas.style.height = `${this.pageSize.height}px`;
      this.context = this.createHiPPICanvas(this.canvas, this.pageSize.width, this.pageSize.height);

      this.canvas.addEventListener('mousedown', this.mouseDownListener);
      this.canvas.addEventListener('touchstart', this.mouseDownListener);
      this.canvas.addEventListener('mousemove', this.mouseMoveListener);
      this.canvas.addEventListener('touchmove', this.mouseMoveListener);
      this.canvas.addEventListener('mouseup', this.mouseUpListener);
      this.canvas.addEventListener('touchend', this.mouseUpListener);

      this.initImageDropListener(this.canvas);
    },
    // ******************************************************************************************************************************
    // Canvas initialization
    // ******************************************************************************************************************************
    updateCanvas() {
      if (this.canvas == null) return;
      const { width, height } = this.canvas;
      const ctx = this.context;
      ctx.clearRect(0, 0, width, height);
      if (this.reportConfig != null && this.reportConfig.accessoryLines != null && (this.showAuxLines || this.snapToAuxLines)) {
        this.drawAuxLines(false);
      }
      if (this.snapToGrid) {
        ctx.strokeStyle = '#000';
        // eslint-disable-next-line vars-on-top
        for (let x = 0; x <= width; x += this.gridSize) {
          // eslint-disable-next-line vars-on-top
          for (let y = 0; y <= height; y += this.gridSize) {
            ctx.strokeRect(x, y, 1, 0);
          }
        }
      }

      this.fields.forEach((field) => {
        if (field.isOnCurrentPage(this.currentPage)) {
          field.renderInContext(ctx, this.activeFields != null && this.activeFields.map((f) => f.config.name === field.config.name).indexOf(true) > -1, this.sharedActiveLanguage);
        }
      });
      // force update mouse pos, thus force update of rulers!

      // draw the active line on top!
      if (this.reportConfig != null && this.reportConfig.accessoryLines != null && (this.showAuxLines || this.snapToAuxLines)) {
        this.drawAuxLines(true);
      }

      this.mouseInfo = { ...this.mouseInfo };
    },
    drawAuxLines(onlyActive) {
      if (this.reportConfig === null || this.reportConfig.accessoryLines == null) return;

      const { width, height } = this.canvas;
      const ctx = this.context;
      ctx.lineWidth = 1;
      ctx.strokeStyle = '#CCC';
      ctx.beginPath();
      this.reportConfig.accessoryLines.forEach((l) => {
        if (!onlyActive || l === this.activeLine) {
          const pos = l.POS;
          if (l.TYPE === 'V') {
            ctx.moveTo(pos, 0);
            ctx.lineTo(pos, height);
          } else {
            ctx.moveTo(0, pos);
            ctx.lineTo(width, pos);
          }
        }
      });
      ctx.stroke();

      if (onlyActive && this.activeLine != null) {
        const font = '12px Arial';
        ctx.font = font;
        const pos = this.activeLine.POS;
        const textMetrics = ctx.measureText(`${pos}`);
        if (this.activeLine.TYPE === 'V') {
          if (pos < this.pageSize.width / 2) {
            ctx.fillText(`${pos}`, pos + 4, 10);
          } else {
            ctx.fillText(`${pos}`, pos - 4 - textMetrics.width, 10);
          }
        } else if (pos < this.pageSize.height / 2) {
          ctx.fillText(`${pos}`, 10, pos - 6);
        } else {
          ctx.fillText(`${pos}`, 10, pos + 12);
        }
      }
    },
    // ******************************************************************************************************************************
    // Pointer movement
    // ******************************************************************************************************************************
    getMouseOverField() {
      let mouseOverField = null;
      // eslint-disable-next-line vars-on-top
      for (let i = this.fields.length - 1; i >= 0; i -= 1) {
        if (this.fields[i].isOnCurrentPage(this.currentPage) && this.fields[i].checkMouseOver(this.mouseInfo)) {
          mouseOverField = this.fields[i];
          break;
        }
      }
      return mouseOverField;
    },
    movePointer(evt) {
      let ot = null;
      let posInElem = null;
      let deltaX = 0;
      let deltaY = 0;
      const oldCursor = this.canvas.style.cursor;
      const oldActiveFields = this.activeFields;

      const mouseOverField = this.getMouseOverField();

      if (this.activeFields != null && mouseOverField != null && this.activeFields.indexOf(mouseOverField) > -1) {
        posInElem = { x: evt.offsetX - mouseOverField.config.x, y: evt.offsetY - mouseOverField.config.y };
        ot = mouseOverField.checkOpType(posInElem);
      }
      if (this.mouseInfo && this.mouseInfo.btn1) {
        this.editTitleAction = false; // mouse moved -> not editing title!
        // perform actual action
        deltaX = parseInt(evt.offsetX - this.mouseInfo.lastX, 10);
        deltaY = parseInt(evt.offsetY - this.mouseInfo.lastY, 10);

        const gridSizeInfo = this.snapToGrid ? this.gridSize : 0;
        const auxLinesInfo = this.snapToAuxLines ? this.reportConfig.accessoryLines : null;

        const upperMaxDistance = Math.min(...this.activeFields.map((field) => field.config.y));
        const leftMaxDistance = Math.min(...this.activeFields.map((field) => field.config.x));
        const lowerMaxDistance = this.pageSize.height - Math.max(...this.activeFields.map((field) => field.config.y + field.config.height));
        const rightMaxDistance = this.pageSize.width - Math.max(...this.activeFields.map((field) => field.config.x + field.config.width));

        const adjustedDeltaX = Math.max(-leftMaxDistance, Math.min(deltaX, rightMaxDistance));
        const adjustedDeltaY = Math.max(-upperMaxDistance, Math.min(deltaY, lowerMaxDistance));

        if (this.activeFields != null && this.activeFields.length > 0) {
          let ongoing = false;
          for (let i = 0; i < this.activeFields.length; i += 1) {
            if (this.activeFields[i].transformationOngoing != null) {
              this.activeFields[i].transformationOngoing(adjustedDeltaX, adjustedDeltaY, gridSizeInfo, auxLinesInfo);
              ongoing = true;
            }
          }

          if (!ongoing) {
            if (ot === EditableField.OperationType.move) {
              this.activeFields.forEach((a) => {
                this.transformationsOngoing = a.moveField(adjustedDeltaX, adjustedDeltaY, gridSizeInfo, auxLinesInfo);
              });
            } else if (ot === EditableField.OperationType.resizeXY && this.activeFields.length === 1) {
              this.transformationsOngoing = [this.activeFields[0].resizeField(deltaX, deltaY, gridSizeInfo, auxLinesInfo)];
            } else if (ot === EditableField.OperationType.resizeX && this.activeFields.length === 1) {
              this.transformationsOngoing = [this.activeFields[0].resizeFieldX(deltaX, 0, gridSizeInfo, auxLinesInfo)];
            } else if (ot === EditableField.OperationType.resizeY && this.activeFields.length === 1) {
              this.transformationsOngoing = [this.activeFields[0].resizeFieldY(0, deltaY, gridSizeInfo, auxLinesInfo)];
            }
          }
          this.notifyActiveFields();
        } else if (this.activeLine != null) {
          // console.log('move line');
          if (this.activeLine.TYPE === 'V') {
            this.activeLine.POS += deltaX;
            this.updateCanvas();
          } else {
            this.activeLine.POS += deltaY;
            this.updateCanvas();
          }
        }
      }

      // adjust cursor
      if (this.activeFields != null && mouseOverField != null && this.activeFields.indexOf(mouseOverField) > -1) {
        // check for a field
        if (ot === EditableField.OperationType.resizeXY) {
          this.canvas.style.cursor = 'nwse-resize';
        } else if (ot === EditableField.OperationType.resizeX) {
          this.canvas.style.cursor = 'ew-resize';
        } else if (ot === EditableField.OperationType.resizeY) {
          this.canvas.style.cursor = 'ns-resize';
        } else if (ot === EditableField.OperationType.move) {
          this.canvas.style.cursor = 'move';
        }
      } else {
        // let's check for auxLines!
        let lineHit = false;
        if (this.reportConfig != null && this.reportConfig.accessoryLines != null) {
          this.reportConfig.accessoryLines.forEach((l) => {
            const pos = l.POS;
            if (this.mouseInfo != null && l.TYPE === 'V' && Math.abs(this.mouseInfo.x - pos) < 3) {
              this.canvas.style.cursor = 'ew-resize';
              lineHit = true;
            } else if (this.mouseInfo != null && l.TYPE === 'H' && Math.abs(this.mouseInfo.y - pos) < 3) {
              this.canvas.style.cursor = 'ns-resize';
              lineHit = true;
            }
          });
        }
        if (!lineHit) {
          this.canvas.style.cursor = 'default';
        }
      }
      this.mouseInfo = {
        ...(this.mouseInfo != null ? this.mouseInfo : {}), x: evt.offsetX, y: evt.offsetY, lastX: evt.offsetX, lastY: evt.offsetY,
      };

      if (oldActiveFields !== this.activeFields || oldCursor !== this.canvas.style.cursor || (this.transformationsOngoing != null && this.transformationsOngoing.length > 0)) {
        this.updateCanvas();
      }
    },
    addVerticalAuxLine() {
      if (!this.reportConfig.accessoryLines) this.reportConfig.accessoryLines = [];
      this.reportConfig.accessoryLines.push({ TYPE: 'V', POS: this.pageSize.width / 2 });
      this.storeInUndoStack();
      this.updateCanvas();
    },
    addHorizontalAuxLine() {
      if (!this.reportConfig.accessoryLines) this.reportConfig.accessoryLines = [];
      this.reportConfig.accessoryLines.push({ TYPE: 'H', POS: this.pageSize.height / 2 });
      this.storeInUndoStack();
      this.updateCanvas();
    },
    getActivePage() {
      return this.currentPage;
    },
    addNewFieldWithType(typeId) {
      if (this.isTemplate) return this.reportConfig;
      if (typeId === Constants.FIELD_TYPES.image) {
        // eslint-disable-next-line no-alert
        alert(this.$t('editor.hintUseDragAndDrop'));
      } else {
        const newConfig = this.addNewFieldConfigWithType(typeId);
        if (typeId === Constants.FIELD_TYPES.date) {
          this.fields.push(new DateField(newConfig));
        } else if (typeId === Constants.FIELD_TYPES.list) {
          this.fields.push(new ListField(newConfig));
        } else if (typeId === Constants.FIELD_TYPES.table) {
          this.fields.push(new TableField(newConfig));
        } else {
          this.fields.push(new EditableField(newConfig));
        }
      }
      return this.reportConfig;
    },
    async addImageField(source, x, y) {
      if (this.isTemplate) return;
      try {
        const { newConfig, image } = await this.addImageFieldConfig(source, x, y);
        this.fields.push(new ImageField(newConfig, image));
        this.updateCanvas();
      } catch (error) {
        console.error(error);
      }
    },
    findFieldsByFieldCnt(idxs) {
      const fields = [];
      idxs.forEach((idx) => {
        for (let i = 0; i < this.reportConfig.fields.length; i += 1) {
          if (idx === this.reportConfig.fields[i].fieldIdx) {
            fields.push(i);
            break;
          }
        }
      });
      return fields;
    },
    moveForwards() {
      if (this.isTemplate) return;
      if (this.activeFields != null && this.activeFields.length > 0) {
        const currIdxs = this.findFieldsByFieldCnt(this.activeFields.map((a) => a.config.fieldIdx));
        const currFieldIdxs = this.activeFields.map((a) => this.fields.indexOf(a));
        for (let i = 0; i < currIdxs.length; i += 1) {
          if (currIdxs[i] < this.reportConfig.fields.length - 1 && currFieldIdxs[i] < this.reportConfig.fields.length - 1) {
            const tmp = this.reportConfig.fields[currIdxs[i]];
            this.reportConfig.fields[currIdxs[i]] = this.reportConfig.fields[currIdxs[i] + 1];
            this.reportConfig.fields[currIdxs[i] + 1] = tmp;
            const tmpField = this.fields[currIdxs[i]];
            this.fields[currIdxs[i]] = this.fields[currIdxs[i] + 1];
            this.fields[currIdxs[i] + 1] = tmpField;
            this.storeInUndoStack();
            this.updateCanvas();
          }
        }
      }
    },
    moveBackwards() {
      if (this.isTemplate) return;
      if (this.activeFields != null && this.activeFields.length > 0) {
        const currIdxs = this.findFieldsByFieldCnt(this.activeFields.map((a) => a.config.fieldIdx));
        const currFieldIdxs = this.activeFields.map((a) => this.fields.indexOf(a));
        for (let i = 0; i < currIdxs.length; i += 1) {
          if (currIdxs[i] > 0 && currFieldIdxs[i] > 0) {
            const tmp = this.reportConfig.fields[currIdxs[i]];
            this.reportConfig.fields[currIdxs[i]] = this.reportConfig.fields[currIdxs[i] - 1];
            this.reportConfig.fields[currIdxs[i] - 1] = tmp;
            const tmpField = this.fields[currIdxs[i]];
            this.fields[currIdxs[i]] = this.fields[currIdxs[i] - 1];
            this.fields[currIdxs[i] - 1] = tmpField;
            this.storeInUndoStack();
            this.updateCanvas();
          }
        }
      }
    },
    editPage(pageNr, e) {
      if (this.isTemplate) return;
      e.preventDefault();
      e.stopPropagation();
      // eslint-disable-next-line no-alert
      const updated = this.$refs.renamePageModal.show(this.reportConfig.pageNames[pageNr]);
      if (updated != null && updated.trim().length > 0) {
        this.reportConfig.pageNames[pageNr] = updated;
        this.storeInUndoStack();
      }
    },
    addPage() {
      if (this.isTemplate) return;
      this.storeInUndoStack();
      this.reportConfig.nrOfPages += 1;
      this.reportConfig.pageNames[this.reportConfig.nrOfPages - 1] = `${this.$t('editor.page')} ${this.reportConfig.nrOfPages}`;
    },
    removePage(pageNr, e) {
      if (this.isTemplate) return;
      e.preventDefault();
      e.stopPropagation();
      // eslint-disable-next-line no-restricted-globals, no-alert
      if (this.reportConfig.nrOfPages > 1 && confirm(this.$t('message.confirmDelete'))) {
        this.fields.forEach((f) => {
          const field = f;
          if (field.isOnCurrentPage(pageNr)) {
            field.config.page = -1;
          }
        });
        this.reportConfig.pageNames.splice(pageNr, 1);
        this.reportConfig.nrOfPages -= 1;
        this.currentPage = 0;
        this.storeInUndoStack();
        this.updateCanvas();
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  #editorCanvas {
    border:none;
    margin-top: 50px;
    margin-left: 0px;
    box-shadow: 4px 4px 20px lightgray;
    background-color: white;
  }

  #horizontalRuler {
    width: calc(100%-300px);
    padding-left: 20px;
    position: absolute;
    z-index: 97;
    background: var(--color_almost_white);
    padding-top: 20px;
  }

  .editorArea {
    display: inline-block;
    position: absolute;
    left: 20px;
    top: 0px;
    min-width: 740px;
    overflow: auto;
  }

  .pageSelector {
    position: fixed;
    bottom: 0px;
    width: 100%;
    display: flex;
    justify-content: left;
    background-color: var(--color_brown);
    color: white;
    padding-bottom: 12px;
    padding-left: 12px;
  }
  .pageLink {
    background: var(--color_brown);
    padding: 8px;
    margin-right: 8px;
    cursor: pointer;
    display: inline-block;
    line-height: 20px;
    border-radius: 0px 0px 12px 12px;
    min-width: 80px;
    text-align: center;
  }
  .pageLink.active {
    background: var(--color_light_brown);
  }

  span.edit {
    cursor: pointer;
  }

  #verticalRuler {
    display: inline-block;
    width: 20px;
  }

  .tabletEditorComponent {
    text-align: left;
    padding: 0px;
    overflow: auto;
    width: 100%;
    min-width: 740px;
    position: absolute;
    height: calc(100% - 185px);
  }

  .dropArrow {
    border: none;
    background: none !important;
    font-size: 18px;
    color: var(--color_gray) !important;
  }

  .disabled {
    color: var(--color_gray);
  }

</style>
